@import "_variables";
@import "../bootstrap/mixins";

// Reset and dependencies
@import "../bootstrap/normalize";
@import "../bootstrap/print";
@import "../bootstrap/glyphicons";

// Core CSS
@import "../bootstrap/scaffolding";
@import "../bootstrap/type";
@import "../bootstrap/code";
@import "../bootstrap/grid";
@import "../bootstrap/tables";
@import "../bootstrap/forms";
@import "../bootstrap/buttons";

// Components
@import "../bootstrap/component-animations";
@import "../bootstrap/dropdowns";
@import "../bootstrap/button-groups";
@import "../bootstrap/input-groups";
@import "../bootstrap/navs";
@import "../bootstrap/navbar";
@import "../bootstrap/breadcrumbs";
@import "../bootstrap/pagination";
@import "../bootstrap/pager";
@import "../bootstrap/labels";
@import "../bootstrap/badges";
@import "../bootstrap/jumbotron";
@import "../bootstrap/thumbnails";
@import "../bootstrap/alerts";
@import "../bootstrap/progress-bars";
@import "../bootstrap/media";
@import "../bootstrap/list-group";
@import "../bootstrap/panels";
@import "../bootstrap/responsive-embed";
@import "../bootstrap/wells";
@import "../bootstrap/close";

// Components w/ JavaScript
@import "../bootstrap/modals";
@import "../bootstrap/tooltip";
@import "../bootstrap/popovers";
@import "../bootstrap/carousel";

// Utility classes
@import "../bootstrap/utilities";
@import "../bootstrap/responsive-utilities";



/*
	//////////////////////////////////////////
	VARIABLES
	//////////////////////////////////////////
*/
$primary-font: Arial, Helvetica, Tahoma, Sans-serif;

$body-text-color: #0F0F0F;
$background-color: #F9F9F9;
$brand-color: #667D60;
$secondary-color: #B4875D;
$accent-color: #FF7800;

$block-vertical-padding: 70px;

$nav-height: 100px;
$social-bar-height: $nav-height/3;



/*
	//////////////////////////////////////////
	USER DEFINED STYLES
	//////////////////////////////////////////
*/

html, body {
	background: $background-color;
	color: $body-text-color;

	font-family: $primary-font;
}

body {
	padding-top: $nav-height / 3;
}

.inverted {
	background: $body-text-color;
	color: $background-color;
}

.branded {
	background: $brand-color;
	color: $background-color;
}

.branded-secondary {
	background: $secondary-color;
	color: $background-color;
}






h1, h2, h3, h4, h5, h6 {

	text-transform: uppercase;

	/* &:first-child {
		margin-top: 0;
	} */
}


h2 {
	font-size: 2.4em;
}

p {
	line-height: 2em;
	font-size: 1.2em;
	margin-bottom: 1.8em;
}

p:last-child {
	margin-bottom: 0;
}


header.sticky + div {
	margin-top: $nav-height - $social-bar-height;
}



#social-bar {

	position: absolute;
	z-index: 100;
	/* background: $background-color; */

	height: $social-bar-height;
	top: 0;
	right: 0;
	left: 0;

	padding: 7px 0 0 0;

	span {
		display: inline-block;
		margin-right: 10px;

		/* font-weight: bold; */
		line-height: 26px;
	}

	a {
		&.at-share-btn {
			max-width: 23px !important;
		}

		.at-icon-wrapper {
			width: 20px !important;
			height: 20px !important;
			margin-right: 10px;

			svg {
				line-height: 20px !important;
				width: 20px !important;
				height: 20px !important;
			}
		}
	}
}



.navbar.navbar-default {

	> .container-fluid {
		background: $background-color;
	}


	#menu-logo {
		padding: 0;
		max-width: 0;
		opacity: 0;

		transition: opacity .5s, max-width .5s .2s;

		img {
			vertical-align: top;
			margin-top: -34px;
			height: 90px;
		}
	}


	.sticky & {
		position: fixed;
		box-shadow: 0 0 15px -3px rgba(0,0,0,0.6);

		transition: box-shadow .5s;

		/* .navbar-header .navbar-brand {
			opacity: 1;
		} */

		#menu-logo {
			opacity: 1;
			max-width: 150px;

			transition: opacity .5s .3s, max-width .5s;
		}
	}

	z-index: 99;
	left: 0;
	right: 0;
	top: 0;
	background: $background-color;
	border: none;
	margin: 0;
	height: $nav-height;

	padding: $nav-height/4 0 0 0;

	.navbar-header {

		.navbar-brand {
			display: none;

			opacity: 0;
			color: $body-text-color;

			transition: opacity .5s;
		}

		.navbar-brand img {
			max-height: 60px;
			margin-top: -20px;
		}


		.navbar-toggle {
			border: none;
			margin-top: 5px;

			.icon-bar {
				background: $body-text-color;
				width: 30px;
				height: 4px;
			}

			&:hover,
			&:focus {
				background: $brand-color;
				color: white;

				.icon-bar {
					background: $background-color;
				}
			}
		}

	}


	.navbar-nav {

		text-align: center;
		width: 100%;
	
		> li {
			display: inline-block;
			float: none;
			font-size: 19px;

			a {
				padding: 20px 50px;
				color: $body-text-color;
			}


			&:hover > a, &.active > a {
				color: $body-text-color;
				background: transparent;
				font-weight: bold;
			}

		}




		&.navbar-right { //social icons
			padding-top: 7px;
		}

	}

}


.btn {
	border-radius: 0;
	border: none;
	padding: 18px 50px;
	text-transform: none;
	font-size: 1.2em;
}

.btn-warning {
	background: $accent-color;

	&:hover {
		background: lighten($accent-color, 10%);
	}
}

.btn-branded {
	background: $brand-color;
	color: white;

	&:hover {
		background: lighten($brand-color, 10%);
		color: white;
	}
}







#header_image {
	text-align: center;

	#logo_overlay {
		width: 500px;
	}
}








.container-fluid.impression-img {
	height: 600px;
	background: #CCCCCC;
	background-size: cover;
	background-position: center;
}

.contentblock {
	position: relative;
	/* min-height: 450px; */
	padding-top: $block-vertical-padding;
	padding-bottom: $block-vertical-padding;


	&.low {
		min-height: 125px;
		height: 125px;
		padding-top: 33px;
		padding-bottom: 33px;

		.icon {
			float: left;
			margin: -10px 27px 0 0;
			img {
				position: static;
			}
		}

		h2 {
			margin-top: 13px;
		}
	}

	.icon {
		vertical-align: bottom;
		text-align: center;
		margin: 0 0 35px 0;
		height: 160px;
		position: relative;

		img {
			height: 80px;
			position: absolute;
			bottom: 0;
			left: 0;
		}

		&.icon-brush img { height: 110px; }
		&.icon-saw img { height: 100px; }
		&.icon-trunk img { height: 140px; }

	}

	.icon-text {

		font-size: 1.1em;
		line-height: 1.8em;

		span {
			font-size: 1.4em;
			text-transform: uppercase;
			display: block;
			margin-bottom: 10px;
		}
	}

	.sponsor {
		height: 60px;
	}
}


.quoteblock {
	position: relative;
	font-size: 60px;
	text-align: left;
	text-transform: uppercase;
	padding-top: $block-vertical-padding;
	padding-bottom: $block-vertical-padding;
	/* padding-right: 40%; */

	span:before {
		content: '"';
		display: inline-block;
	}

	span:after {
		content: '"';
		display: inline-block;
	}

	.btn {
		font-size: 17px;
	}
}



.has-arrow {

	&:after {
		content: "";
		width: 1px;
		height: 1px;
		border-top: 50px solid $background-color;
		border-left: 50px solid transparent;
		border-right: 50px solid transparent;

		position: absolute;
		left: 50%;
		bottom: -49px;
		margin-left: -570px;
	}

	.inverted &:after {
		border-top-color: $body-text-color;
	}

	.branded &:after {
		border-top-color: $brand-color;
	}

	.branded-secondary &:after {
		border-top-color: $secondary-color;
	}
}




#agenda {

	.agendaItem {

		>div:nth-child(2)
		{
			font-weight: bold;
		}
	}

	/* table tr {
		padding-bottom: 25px;
		border-bottom: 1px solid black;
	}

	table tr td {
		padding: 0 25px 0 0;
		font-size: 1.2em;
		vertical-align: bottom;
	}

	td:nth-child(2) {
		font-weight: bold;
	} */

	a {
		font-weight: normal;
		color: $body-text-color;
	}

}













#filters {

	p {
		font-size: 1em;
		line-height: 1.8em;
	}

	input[type="checkbox"] {
		position:absolute;
		visibility: hidden;
	}

	input[type="checkbox"] {

		+ label {
			display: block;
			position: relative;
			padding: 0 20px;

			cursor: pointer;
		}


		+ label:before, + label:after {
			content: "";
			display: inline-block;
			position: absolute;
			left: 0;
			top: 1px;
			width: 15px;
			height: 15px;
			border: 1px solid $body-text-color;
			background: $background-color;
		}

		&:checked + label:after {
			width: 11px;
			height: 11px;
			top: 3px;
			left: 2px;
			background: $body-text-color;
		}
	}
}


.wall-item {
	$hover-add-size: 30px;
	$hover-transition-time: .8s;

	display: block;
	color: inherit;
	overflow: hidden;
	width: 200px;
	height: 270px;

	.wall-item-image-holder {
		overflow: hidden;
		width: 200px;
		height: 200px;

		.wall-item-image {
			background-size: cover;
			background-position: center;

			width: 200px;
			height: 200px;
			transition: all $hover-transition-time;
		}
	}


	&:hover {

		color: inherit;
		text-decoration: none;

		.wall-item-image {
			margin-top: -($hover-add-size/2);
			margin-left: -($hover-add-size/2);
			width: 200px + $hover-add-size;
			height: 200px + $hover-add-size;

			transition: all $hover-transition-time;
		}
	}

	.wall-item-data {
		padding: 5px;

		ul {
			list-style: none;
			padding: 0;

			li:first-child {
				font-weight: bold;
			}

			/* li:last-child {
				color: lighten($body-text-color, 50%);
			} */
		}
	}



	& > .for_sale,
	& > .for_rent
	{
		opacity: 0;
		background: $accent-color;
		color: white;
		font-size: 14px;
		font-weight: bold;
		height: 100px;
		padding: 80px 100px 0 100px;
		transform: rotate(-45deg);
		position: absolute;
		top: -45px;
		left: -120px;
	}

	/* & > .for_rent {
		background: $brand-color;
	} */
}



#project-items-holder {

	$scalePercentage: 10%;
	$itemMargin: 20px;
	$largeItem: 500px;
	$smallItem: 110px;

	width: $smallItem*3 + $largeItem;


	#project-items {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		height: $largeItem;
		align-content: flex-start;
	}


	.project-item {

		height: $smallItem;
		width: $smallItem;
		overflow: hidden;
		margin: 0 $itemMargin $itemMargin 0;
		position: relative;

		&:nth-child(4n+4) {
			margin: 0 $itemMargin 0 0;
		}

		a {
			display: block;
			width: 100%;
			height: 100%;

			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;

			transition: all .5s;

			&:hover {

				margin: (0 - ($scalePercentage/2)) 0 0 (0 - ($scalePercentage/2));
				width: 100% + $scalePercentage;
				height: 100% + $scalePercentage;

				transition: all .5s;
			}
		}

		&.default {
			float: left;
			margin: 0 20px 0 0;
			width: $largeItem;
			height: $largeItem;
			max-width: 100%;

			.for_sale, .for_rent {
				opacity: 0;
				background: $accent-color;
				color: white;
				font-size: 18px;
				font-weight: bold;
				height: 200px;
				padding: 165px 100px 0 100px;
				transform: rotate(-45deg);
				position: absolute;
				top: -100px;
				left: -130px;
			}
		}
	}
}


.inline-share {
	font-size: 17px;
	font-weight: bold;

	.addthis_sharing_toolbox {
		position: relative;
		top: 10px;
		margin-right: 10px;
		display: inline-block;
	}
}

h1.project-name {
	text-transform: none;
	font-size: 1.3em;
	font-weight: bold;
}

.project-description {
	margin-top: 15px;
}









.fancybox-overlay {
	background: rgba(0,0,0,0.8);
}

.fancybox-close {
	border-radius: 50%;
	border: 3px solid white;
	box-shadow: 0 0 5px -1px rgba(0,0,0,0.6);
	background: black;
	color: white;
	font-weight: bold;

	&:hover {
		color: white;
		text-decoration: none;
	}

	&:after {
		content: "x";
		font-size: 22px;
		position: relative;
		top: -2px;
		left: 9px
	}
}

.fancybox-nav {
	width: 30%;
}

.fancybox-prev,
.fancybox-next {

	&:after {
		content: "";
		position: absolute;
		top: 50%;
		left: 50%;
		width: 50px;
		height: 50px;
		margin: -25px 0 0 -35px ;
		transform: rotate(45deg);
		border-top: 7px solid rgba(255,255,255,0);
		border-right: 7px solid rgba(255,255,255,0);
		background: rgba(255,255,255,0);

		transition: all .5s;
	}

	&:hover {
		background: rgba(255,255,255,0.3);
		transition: background .8s;

		&:after {
			border-top: 7px solid rgba(255,255,255,0.7);
			border-right: 7px solid rgba(255,255,255,0.7);

			transition: all .5s;
		}

	}
}

.fancybox-prev:after {
	margin: -25px 0 0 -15px;
	transform: rotate(-135deg);
}







.form-control {
	border-radius: 0;
	border: 1px solid black;
	background: white;
}




ul.contactdata {
	list-style: none;
	text-indent: 0;
	padding: 0;
	font-size: 1.2em;
}


#contactdata-holder h3 {
	margin-top: 0;
}


#follow-block {

	.follow-icon {

		display: block;
		float: left;
		width: 80px;
		height: 80px;
		margin: 0 15px 15px 0;
		background-size: cover;
		background-position: center;

		&:nth-of-type(2n + 3) {
			clear: left;
		}

		&:hover {
			opacity: 0.9;
		}

		&.facebook {
			background-image: url(/img/content/follow-icon-facebook.png);
		}
		&.pinterest {
			background-image: url(/img/content/follow-icon-pinterest.png);
		}
		&.instagram {
			background-image: url(/img/content/follow-icon-instagram.png);
		}
		&.linkedin {
			background-image: url(/img/content/follow-icon-linkedin.png);
		}
		&.youtube {
			background-image: url(/img/content/follow-icon-youtube.png);
		}
	}
}






footer {

	/* margin-top: $block-vertical-padding; */

	#contactdata-holder h3 {
		margin-top: 20px;
		margin-bottom: 20px;
	}

	h3 {
		margin-bottom: 20px;
	}


	#claims {
		text-align: center;
		font-size: 11px;
		padding: 0 0 4px 0;
		height: 18px;
	}
}


















/* Large desktops and laptops */
@media (min-width: 1200px) {
	
	.icon.icon-trophy {
		padding-left: 7px;
	}
}

/* Landscape tablets and medium desktops */
@media (min-width: 992px) and (max-width: 1199px) {
	.has-arrow {

		&:after {
			margin-left: -480px;
		}
	}
}

/* Portrait tablets and small desktops */
@media (min-width: 768px) and (max-width: 991px) {

	.navbar.navbar-default {
		.navbar-nav > li a {
			padding: 15px 30px;
		}
	}

	.icon-text {
		margin-bottom: 60px;
	}


	.has-arrow {

		&:after {
			margin-left: -360px;
		}
	}

	#project-items-holder {
		width: 380px;

		.project-item.default {
			width: 380px;
			height: 380px;
		}

		#project-items {
			height: 380px;

			.project-item {
				width: 80px;
				height: 80px;
			}


			.project-item:nth-child(4n+4) {
				margin: 0 20px 0 0;
			}

		}
	}

	#follow-block {
		float: right;
	}
}


@media (max-width: 767px) and (orientation: landscape)
{
	body.mobile:after {
		content: "";
		text-transform: uppercase;
		font-size: 20px;
		position: fixed;
		color: white;
		text-align: center;
		vertical-align: center;
		padding: 20% 0 0 0;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		background-color: $brand-color;
		background-image: url(/img/layout/rotate_icon.png);
		background-repeat: no-repeat;
		background-position: center;
		z-index: 99999999999999;
	}
}

/* Landscape phones and portrait tablets */
@media (max-width: 767px) {

	.navbar.navbar-default .navbar-nav > li {
		display: block;

		&#menu-logo {
			display: none;
		}
	}

	.contentblock.low h2 {
		margin-top: -3px;
	}

	.btn-warning, .btn-branded {
		margin-top: 25px;
	}

	.icon-text {
		margin-bottom: 60px;
	}


	.has-arrow {

		&:after {
			left: 0;
			margin-left: 0;
		}
	}

	.agendaItem {
		margin-bottom: 25px;
	}

	#follow-block, #contactdata-holder {
		margin-bottom: 35px;
	}
}

/* Portrait phones and smaller */
@media (max-width: 480px) {

	h1 {
		font-size: 31px;
	}


	#social-bar {

		.sticky & {
			display: none;
		}
	}

	#header_image {
		text-align: center;
		background-size: 250%;

		#logo_overlay {
			width: 280px;
			
		}
	}


	.navbar.navbar-default {

		.navbar-header {

			.navbar-nav {

				> li {

					a {
						padding: 35px 50px;
					}
				}

			}
		}

		.navbar-collapse {
			position: relative;
			top: 15px;
		}
	}



	.container-fluid.impression-img {
		height: 400px;
	}


	.contentblock {
		min-height: 400px;


		&.low {
			min-height: 125px;
			height: 125px;
			padding-top: 15px;
			padding-bottom: 20px;

			.icon {
				margin-top: 10px;
			}

			h2 {
				font-size: 27px;
				margin-top: 20px;
			}
		}

		.sponsor {
			margin-top: 20px;
			height: 50px;
		}
	}

	.quoteblock {
		font-size: 35px;
		padding-right: 20%;
	}


	.has-arrow {

		&:after {
			left: 0;
		}
	}


	#agenda {
		/* table tr td {
			padding: 0 10px 0 0;
			font-size: 17px;

			&:first-child {
				font-weight: bold;
				font-size: 13px;
				bottom: 3px;
			}
		} */
	}


	#project-items-holder {
		width: 380px;

		.project-item.default {
			width: 380px;
			height: 380px;
		}

		#project-items {
			padding-top: 15px;
			flex-direction: row;
			float: none;
			clear: left;
			height: auto;
	
			.project-item {
				width: 113px;
				height: 113px;
			}

			.project-item:nth-child(3n+3) {
				margin: 0 0 20px 0;
			}

			.project-item:nth-child(4n+4) {
				margin: 0 20px 20px 0;
			}

		}
	}

	.inline-share .addthis_sharing_toolbox {
		float: right;
	}

	#contactdata-holder {

		clear: both;
		float: none !important;
		margin-bottom: 25px;
	}


}



@media(max-width: 430px) {
	.contentblock.low h2 {
	    margin-top: 6px;
	}
}



@media(max-width: 376px) {

	h1 {
		font-size: 26px;
	}

	.contentblock.low h2 {
	    font-size: 22px;
	}

	#project-items-holder {
		width: 345px;

		.project-item.default {
			width: 345px;
			height: 345px;
		}

		#project-items {
	
			.project-item {
				width: 101px;
				height: 101px;
			}
		}
	}

}


@media(max-width: 321px) {

	.contentblock.low h2 {
	    font-size: 22px;
	}

	#project-items-holder {
		width: 290px;

		.project-item.default {
			width: 290px;
			height: 290px;
		}

		#project-items {
	
			.project-item {
				width: 83px;
				height: 83px;
			}
		}
	}

}